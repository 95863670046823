import { FC } from 'react';
import './SectionContact.scss';
import { Section } from '@components/index';
import logo from '../../logo.svg';

interface Props {
  section: any;
}

export const SectionContact: FC<Props> = ({ section }) => {
  return (
    <Section section={section}>
      {section.columns && (
        <div className="columns">
          {section.columns.map((col, colIndex) => (
            <div key={colIndex} className="column">
              <h3>{col.title}</h3>
              {col.days && (
                <ul>
                  {col.days.map((day, dayIndex) => (
                    <li key={dayIndex}>
                      {day.day}: {day.hours}
                    </li>
                  ))}
                </ul>
              )}

              {col.address && (
                <>
                  <a href={col.address_link} target="_blank" rel="noreferrer">
                    <address
                      dangerouslySetInnerHTML={{ __html: col.address }}
                    />
                  </a>
                  {col.kvk && <p>KVK: {col.kvk}</p>}
                </>
              )}

              {col.phone && (
                <a href={'tel:' + col.phone.replaceAll(' ', '')}>
                  📞 {col.phone}
                </a>
              )}
              {col.email && (
                <>
                  <a href={'mailto:' + col.email}>✉️ {col.email}</a>
                  <p>🌐 {window.location?.href}</p>
                </>
              )}
            </div>
          ))}
        </div>
      )}

      <div className="columns">
        <div className="column socials">
          {section.instagram && (
            <a
              href={'https://instagram.com/' + section.instagram}
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/img/instagram.png" alt="instagram" />
            </a>
          )}
          {/* {section.linkedin && (
            <a
              href={'https://instagram.com/' + section.linkedin}
              target="_blank"
              rel="noreferrer"
            >
              <img src="/assets/img/linkedin.png" alt="linkedin" />
            </a>
          )} */}
        </div>
        <div className="column">
          <img src={logo} alt="njoy logo" className="logo" />
        </div>
      </div>
    </Section>
  );
};
