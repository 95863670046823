import { FC } from 'react';
import './SectionHome.scss';
import { Section } from '@components/index';
import logo from '../../logo.svg';

interface Props {
  section: any;
}

export const SectionHome: FC<Props> = ({ section }) => {
  return (
    <Section section={section} showTitle={false}>
      <img src={logo} alt={section.title} className="logo" />
    </Section>
  );
};
