import { FC, PropsWithChildren, ReactNode } from 'react';
import './Section.scss';

interface Props extends PropsWithChildren {
  section: any;
  showTitle?: boolean;
  fullHeight?: boolean;
  prefix?: React.ReactNode;
}

export const Section: FC<Props> = ({
  section,
  showTitle = true,
  fullHeight = true,
  children,
  prefix,
}) => {
  const sectionTitle = section.title.toLowerCase().replaceAll(' ', '-');

  return (
    <section
      id={sectionTitle}
      className={`section section__${sectionTitle} ${fullHeight && 'fullheight'}`}
    >
      {prefix}
      <div className="section__inner">
        {showTitle && (
          <h2 className={`section__title section__${sectionTitle}__title`}>
            {section.title_override || section.title}
          </h2>
        )}

        {children}
      </div>
    </section>
  );
};
