import { FC } from 'react';
import './SectionPrices.scss';
import { Section } from '@components/index';
import prices from '@data/prices.json';

interface Props {
  section: any;
}

export const SectionPrices: FC<Props> = ({ section }) => {
  return (
    <Section section={section}>
      <div className="section-prices">
        {Object.entries(prices).map(([category, services]) => (
          <div key={category} className="price-category">
            <h3>{category}</h3>
            <ul>
              {Object.entries(services).map(([service, price]) => (
                <li key={service} className="price-item">
                  <span className="service">{service}</span>
                  <span className="price">{price}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Section>
  );
};
