import { FC } from 'react';
import './SectionAbout.scss';
import { Section } from '@components/index';

interface Props {
  section: any;
}

export const SectionAbout: FC<Props> = ({ section }) => {
  return (
    <Section section={section}>
      {section.text && (
        <article
          className="text"
          dangerouslySetInnerHTML={{ __html: section.text }}
        />
      )}
      <img src="/assets/img/joyce1.jpeg" alt="Joyce" className="image" />
    </Section>
  );
};
