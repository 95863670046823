import { FC } from 'react';
import './SectionAppointment.scss';
import { Section } from '@components/index';

interface Props {
  section: any;
}

export const SectionAppointment: FC<Props> = ({ section }) => {
  return (
    <Section
      section={section}
      // showTitle={false}
      prefix={
        <div className="menu-helper-wrapper">
          <div className="menu-helper" id="menu-helper"></div>
        </div>
      }
    >
      <img src="/assets/img/joyce2.jpeg" alt="Joyce" className="image" />
      {section.booking_widget && (
        <iframe
          src={section.booking_widget}
          title="Booking Widget"
          className="booking-widget"
        />
      )}
    </Section>
  );
};
