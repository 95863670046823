import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './global.scss';
import {
  SectionHome,
  SectionAbout,
  SectionPrices,
  SectionAppointment,
  SectionContact,
  Navigation,
} from '@components/index';
import siteData from '@data/site_data.json';

import '@fontsource/quicksand';
import '@fontsource/quicksand/700.css';

document.addEventListener('DOMContentLoaded', () => {
  const navigation = document.querySelector('.navigation');
  const sections = document.querySelectorAll('.menu-helper');

  // Function to handle background color change
  const handleBackgroundColor = (id) => {
    if (id === 'maak-afspraak') {
      navigation?.classList.add('enforce-white');
    } else {
      navigation?.classList.remove('enforce-white');
    }
  };

  // Check initial section visibility on page load
  const checkInitialSection = () => {
    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        handleBackgroundColor(section);
      }
    });
  };

  // IntersectionObserver for detecting section intersections on scroll
  const observer = new IntersectionObserver(
    (entries) => {
      const visibleSection = entries.find((entry) => entry.isIntersecting);
      console.log(visibleSection?.target.id);
      if (visibleSection) {
        handleBackgroundColor(visibleSection?.target.id);
      }
    },
    {
      root: null,
      threshold: [0.95], // Fire when 75% of the section is visible
      rootMargin: `-24px 0px 0px 0px`, // Offset for the fixed navigation bar
    }
  );

  // Observe all sections
  sections.forEach((section) => observer.observe(section));

  checkInitialSection();
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <div className="App">
      <SectionHome section={siteData.sections[0]} />
      <SectionAbout section={siteData.sections[1]} />
      <SectionPrices section={siteData.sections[2]} />
      <SectionAppointment section={siteData.sections[3]} />
      <SectionContact section={siteData.sections[4]} />
      <Navigation />
    </div>
  </StrictMode>
);
