import { FC, useEffect, useState } from 'react';
import './Navigation.scss';
import siteData from '@data/site_data.json';

export const Navigation: FC<any> = () => {
  const sections = siteData.sections;
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [threshold, setThreshold] = useState<number>(0.7); // Default threshold for scrolling down
  let lastScrollY = 0; // To track the last scroll position

  const sectionTitle = (section) =>
    section.title.toLowerCase().replaceAll(' ', '-');

  useEffect(() => {
    const sectionElements = sections.map((section) =>
      document.getElementById(sectionTitle(section))
    );

    const observer = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find((entry) => entry.isIntersecting);
        if (visibleSection) {
          setActiveSection(visibleSection.target.id);
        }
      },
      {
        root: null, // relative to the viewport
        threshold: threshold, // use the dynamic threshold
      }
    );

    sectionElements.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionElements.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, [sections, threshold]);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    // Determine if scrolling up or down
    if (currentScrollY > lastScrollY) {
      // Scrolling down
      setThreshold(0.7); // Set threshold for scrolling down
    } else {
      // Scrolling up
      setThreshold(0.3); // Set threshold for scrolling up
    }

    lastScrollY = currentScrollY; // Update the last scroll position
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`navigation${showMenu ? ' visible' : ''}`}>
      <span
        className="navigation__toggle"
        onClick={() => setShowMenu((menu) => !menu)}
      >
        {showMenu ? 'Close' : 'Menu'}
      </span>
      <ul className={`navigation__list`}>
        {sections.map((section, index) => (
          <li
            key={index}
            className={activeSection === sectionTitle(section) ? 'active' : ''}
          >
            <a
              href={`#${sectionTitle(section)}`}
              onClick={() => setShowMenu(false)}
            >
              {section.title_override || section.title}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};
